import { isWebview } from '@/utils'

export enum DeviceType {
  androidApp = 'androidApp',
  androidWeb = 'androidWeb',
  iosWeb = 'iosWeb',
  web = 'web',
}

/**
 * iOS 디바이스인지 판별
 */
export const isIOS = () => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator.userAgent.toLowerCase()

  // iPhone, iPad 감지
  // iPadOS 13 이상에서는 iPad가 데스크탑처럼 **MacIntel**로 표시된다. 따라서 체크가 안됨.
  return /iphone|ipad|ipod/.test(userAgent)
}

/**
 * 디바이스 타입을 판별하여 반환
 * @returns
 * androidApp: 안드로이드 앱
 * androidWeb: 안드로이드 웹
 * iosWeb: iOS 웹
 * web: 웹
 */
export const detectDeviceType = () => {
  if (isWebview()) return DeviceType.androidApp
  if (/Android/i.test(navigator.userAgent)) return DeviceType.androidWeb
  if (isIOS()) return DeviceType.iosWeb
  return DeviceType.web
}
