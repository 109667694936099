import Link from 'next/link'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import type { GNB_MENUS } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { useCreateBoardModal } from '@/hooks/useCreateBoardModal'
import useVirtuosoSnapshotReset from '@/hooks/useVirtuosoSnapshotReset'
import BetaBadge from '../BetaBadge'

export default function SubMenu({
  subMenu,
  closeModal,
}: {
  subMenu: (typeof GNB_MENUS)[0]['subMenus'][0]
  closeModal: () => void
}) {
  const { text, url, isBeta, gaSelector, parentUrl, privateUrl } = subMenu
  const pathname = usePathname()
  const { isLoggedIn } = useAuth()
  const { resetScroll } = useVirtuosoSnapshotReset()
  const { handleCreateBoardAuth } = useCreateBoardModal('promotion')
  const isHightlighted = Boolean(pathname?.includes(parentUrl || url)) // 해당 페이지의 상위 url에 하이라이트 처리

  const linkUrl = privateUrl && isLoggedIn ? privateUrl : url

  return (
    <li
      className={clsx('font-bold', 'text-lg', 'mb-3', 'last:mb-0', {
        'text-lavendar-500': isHightlighted,
      })}
    >
      <div className="flex items-center">
        {text === '질문하기' ? (
          <button
            data-ga={gaSelector}
            type="button"
            onClick={() => {
              closeModal()
              // 마이케어 페이지에서는 질문작성 시 페이지를 새로고침해야 한다.
              handleCreateBoardAuth()
            }}
            className="block w-full font-bold text-left"
          >
            {text}
          </button>
        ) : (
          <Link
            data-ga={gaSelector}
            href={linkUrl}
            className="flex items-center"
            onClick={() => {
              closeModal()
              resetScroll()
            }}
          >
            {text}
          </Link>
        )}
        {isBeta && <BetaBadge />}
      </div>
    </li>
  )
}
