import Image from 'next/image'
import clsx from 'clsx'
import { S3_ICON_URL } from '@/config'
import { ANDROID_APP_STORE } from '@/consts'
import { isWebview } from '@/utils'
import { isIOS } from '@/utils/mobileDetect'
import MaterialSymbol from '@/v1/MaterialSymbol'

const MENUS = [
  {
    icon: 'social_media_kakao',
    link: 'https://pf.kakao.com/_VUuxfs',
    gaSelector: 'menu_pluschannel',
  },
  {
    icon: 'social_media_blog',
    link: 'https://m.blog.naver.com/lunitcare_',
    gaSelector: 'menu_blog',
  },
  {
    icon: 'social_media_instagram',
    link: 'https://www.instagram.com/lunit.care/',
    gaSelector: 'menu_instagram',
  },
  {
    icon: 'social_media_facebook',
    link: 'https://www.facebook.com/p/Lunit-CARE-100086375075623/',
    gaSelector: 'menu_facebook',
  },
]

const SocialMediaMenu = () => {
  const isApp = isWebview()

  if (isApp) return null

  return (
    <div className="bg-grey-100 pt-5 px-md pb-28">
      {!isIOS() && (
        <div className="mb-5 inline-block">
          <a
            data-ga="menu_app"
            className={clsx(
              'border',
              'border-grey-700',
              'rounded-[8px]',
              'h-9',
              'flex',
              'items-center',
              'px-3',
            )}
            href={ANDROID_APP_STORE}
            target="blank"
          >
            <MaterialSymbol
              name="download"
              size={16}
              className="mr-1.5 fill-grey-700"
            />
            <span className="prose-p3">앱 다운로드</span>
          </a>
        </div>
      )}

      <div className="flex gap-x-5">
        {MENUS.map(({ icon, link, gaSelector }, id) => (
          <a
            key={id}
            href={link}
            data-ga={gaSelector}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={`${S3_ICON_URL}/${icon}.svg`}
              width={28}
              height={28}
              alt=""
            />
          </a>
        ))}
      </div>
      <div />
    </div>
  )
}

export default SocialMediaMenu
